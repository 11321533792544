<template>
    <div>
      <position-list></position-list>
    </div>
  </template>
  
  <script>
  
  export default {
  }
  </script>
  